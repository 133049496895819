import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue1({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Updated newsletter about my freelance journey"
            pageSocialImage=""
            pageTitle="Issue #1"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #1"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "Happy new year everyone.", type: "paragraph"},
                        {spans: [], text: "You might be wondering what this email is, well I’ve decided to give my newsletter a purpose. Since going freelance in September of 2020, I’ve had a lot of people who are interested in hearing my story.", type: "paragraph"},
                        {spans: [], text: "So this newsletter will share exclusive, transparent content on the first Sunday of each month. It’ll be a behind the scenes of my life as a freelancer and look at things like:", type: "paragraph"},
                        {spans: [], text: "My goals for the month", type: "list-item"},
                        {spans: [], text: "What I’ve been working on", type: "list-item"},
                        {spans: [], text: "How much I earned and more", type: "list-item"},
                        {spans: [], text: "So without further ado, I’d like to introduce issue #1.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at December"
                    content={[
                        {spans: [], text: "Going into December, I thought I was going to be fully booked. I had a contract front-end development project to work on and I also had a new product design project scheduled in, but this got delayed as the client was waiting for funds from investors.", type: "paragraph"},
                        {spans: [], text: "With the Christmas break and the extra days unbooked, this month was much slower than I had expected – but this wasn’t a bad thing.", type: "paragraph"},
                        {spans: [], text: "I managed to book in an extra day a week on my contract project (they insisted as they had too much work and a deadline to hit). The rest of the time, I did some house keeping for the year and spent more time with my family.", type: "paragraph"},
                        {spans: [], text: "In total, I worked on this contract project for 11 days in December and billed £3,300 for this work.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking ahead to January"
                    content={[
                        {spans: [], text: "January and the following months are pretty much fully booked. I have my contract project schedules in for 3 days a week, an ongoing design project for a client I started working with in October and the product design project I had lined up for December.", type: "paragraph"},
                        {spans: [], text: "When I started freelancing, I thought the difficulty would be filling my days with paid work. Now, the challenge is keeping some time free to pick up any small tasks for existing clients.", type: "paragraph"},
                        {spans: [], text: "It’s important to keep a schedule of what you’re working on and have lined up, but it’s equally as important to maintain some fluidity for any unknowns.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 January goals"
                    checkList={[
                        {complete: false, text: "💰 Earn £7,000"},
                        {complete: false, text: "⚖️ Weigh 184lbs (-6lbs🔻)"},
                        {complete: false, text: "📙 Listen to I’ll teach you to be rich"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="For those building up to go freelance, don’t underestimate the relationships you’re already building. The contract project I’m working on, came from someone I used to work with over 4 years ago."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/dcb5667b-ce86-4069-b376-39925bb3f331_dec-2020-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £6.2k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/64bc4417-e3a2-4bb1-998f-69c7f4fb7159_chilly-bottles.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Chilly Bottle Series 2", type: "heading4"},
                        {spans: [], text: "My favourite product this month is the new Chilly stainless steel water bottle. I bought my first stainless steel water bottle early-mid 2019 and it was probably the best purchase I made that year. I like to drink water and there is nothing worse than your cold water going warm within minutes of pouring it – the Chilly bottle keeps your drink cold for 24 hours.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.chillys.com/uk/products/bottle-series-2-granite?sku=B500S2GGRY&collar=A_COLLAR_S2GGRY&lid=A_LOOP_S2GGRY",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/07fc7339-d3d5-480d-8d15-dc5b2bdf3a4c_book-click.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Click by Paul Boag", type: "heading4"},
                        {spans: [], text: "I’ve been reading Click on and off for a few months now. The book looks at different dark patterns that are used in digital projects. Paul does a great job of including real world examples of where he’s come across these problems with his clients and solutions he used in place of dark patterns – which lead to better results. Whether you’re a designer or developer I think you could take some useful information away from this book.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.smashingmagazine.com/printed-books/click/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue1;
